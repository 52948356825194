import { Component } from '@angular/core';

import { Plugins } from '@capacitor/core';
import { HttpService } from './services/http.service';
import { MenuService } from './services/menu.service';
import { MenuController } from "@ionic/angular";
import {MessagesService } from './services/messages.service';
import { AuthService } from './services/auth.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError,NavigationExtras } from '@angular/router';
import { browser } from 'protractor';
import {IonInfiniteScroll, ModalController,LoadingController,Platform} from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
//import {PhoneCallTrap} from 'io.gvox.plugin.phonecalltrap/www/PhoneCallTrap';

import { CallLog, CallLogObject } from '@ionic-native/call-log/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ToastService } from './services/toast.service';


const { SplashScreen,Browser } = Plugins;
declare var PhoneCallTrap: any;
//phone calls
declare var window: any;

const { PushNotifications,LocalNotifications } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [MessagesService]
})
export class AppComponent {
  isWsAvailable=localStorage.getItem('callingnumber');

  navigate : any;
  showLevel1 = null;
  showLevel2 = null;
  showLevel3 = null;
  marbledata;
  settingdata;
  marblenames=[];
  marblecolors=[];
  marblecategory=[];
  marblecountry=[];
  mediaplatform=[];
  loginname;
  interval;
  department_id;
  searchmobile={
    number:''
      };
      
  postDatasync = {
    user_id: localStorage.getItem('login_userid'),
    sync_date:localStorage.getItem('sync_date')
    
  };
  public flag: boolean;

  iscontact=localStorage.getItem('callingnumber');
  constructor(private toastService: ToastService,private callLog: CallLog,private androidPermissions: AndroidPermissions,private plt:Platform,private authService: AuthService,private MenuService: MenuService,private HttpService: HttpService,private menuCtrl: MenuController,private Router: Router,private MessagesService:MessagesService) {
   // this.opencallpopup();
   

   localStorage.setItem('callingnumber','');
   localStorage.setItem('isModalOpen',"");
    this.initializeApp();
   // this.checkPermissionCall();
    //this.checkPermissionREAD_CONTACTS();
    this.plt.ready().then(() => {
      

      
      //this.addListenNFC();
      
  });
    this.loginname=localStorage.getItem('login_name');
    
    localStorage.setItem('sync_date','');
      this.plt.ready().then(() => {
       
         // start an interval with a delay of 30 min
         this.runonetime();

         

         
         
      });
   
    localStorage.setItem('last_marble_id',"");
   
    

     

    if(this.Router.url=="/home" || this.Router.url=="/home/feed" || this.Router.url=="")
    {
        this.menuCtrl.enable(true);
    }
    Browser.addListener('browserPageLoaded', () =>{
        console.log("browser event called");
    })
    Browser.addListener('browserFinished', ()=>{
        console.log("browserfinish");
      
    })
    Browser.prefetch({
      urls:['https://shop.quarry.asia']
    })
  }

  
  showNotification () {
     LocalNotifications.schedule({
      notifications: [
        {
          title: "hello",
          body: "hello",
          id: 1,
          schedule: { at: new Date(Date.now() + 1000 * 5) },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: "",
        },
      ],
    });
  }
 
 
 
  
 runonetime() {
  
    
   
      if(localStorage.getItem('department_id')=="" && localStorage.getItem('department_id')=="0"){
           this.sideMenu();
      }
      else
      {
       
        this.MessagesService.getmarblenamesjsonapi(this.postDatasync).subscribe(
          (res: any) => {
          
            localStorage.setItem('marbleinfo',JSON.stringify(res));
            localStorage.setItem('glcnt',res.glcnt);
            localStorage.setItem('wcnt',res.wcnt);
             
            localStorage.setItem('is_sync','yes');
            localStorage.setItem('sync_date',new Date().toISOString());
          },
          (error: any) => {
           console.log("error network");
          }
        );
      }

  
  }

  
  
   

  
  
  clearmarbleid()
  {
    localStorage.setItem('page_marble_id','');
  }
  initializeApp() {
    this.plt.ready().then(() => {
      this.menuCtrl.enable(true);
      SplashScreen.hide();
      this.plt.backButton.subscribeWithPriority(0, () => {
        navigator['app'].exitApp();
       
     });
    });
     
    
  }
  isLevel1Shown(idx: string) {
    return this.showLevel1 === idx;
  }
  toggleLevel3(idx: string) {
    if (this.isLevel3Shown(idx)) {
      this.showLevel3 = null;
    } else {
      this.showLevel2 = idx;
      this.showLevel3 = idx;
    }
  }
  isLevel3Shown(idx: string) {
    return this.showLevel3 === idx;
  }
  
  isLevel2Shown(idx: string) {
    return this.showLevel2 === idx;
  }
  toggleLevel1(idx: string) {
    if (this.isLevel1Shown(idx)) {
      this.showLevel1 = null;
    } else {
      this.showLevel1 = idx;
    }
  }
  toggleLevel2(idx: string) {
    if (this.isLevel2Shown(idx)) {
      this.showLevel2 = null;
    } else {
      this.showLevel1 = idx;
      this.showLevel2 = idx;
    }
  }
  clearLevel() {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
    this.menuCtrl.enable(false);
  }
  clearLevelfilter(id,filtertype) {
    
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
    if(filtertype=="country"){
      this.MessagesService.postData.countryinput=''+id+'';
    }
    if(filtertype=="color"){
      this.MessagesService.postData.colorinput=''+id+'';
    }
    if(filtertype=="application"){
      this.MessagesService.postData.applicationinput=''+id+'';
    }
    if(filtertype=="marble"){
      this.MessagesService.postData.marblenameinput=''+id+'';
    }
    localStorage.setItem('marblefilter','Yes');
    localStorage.setItem('marblefiltermenu','Yes');
    this.menuCtrl.enable(false);
  }
  
  managemedia(mediatype)
  {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
    this.menuCtrl.enable(false);
    

    localStorage.setItem('social_post',mediatype);
    localStorage.setItem('isfilter','yes');
    this.menuCtrl.enable(true);
    this.Router.navigate(['/home/feed']);

    
  }
  clearLevelhome() {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
    this.menuCtrl.enable(true);
  }
  clearshop()
  {
    this.showLevel1 = null;
    this.showLevel2 = null;
    this.showLevel3 = null;
    this.menuCtrl.enable(true);
    Browser.open({toolbarColor:"#000", url:'https://shop.quarry.asia'});
  }
  
  sideMenu()
  {
    
    this.loginname=localStorage.getItem('login_name');
    var color="";
    this.marbledata='';
    this.department_id=localStorage.getItem('department_id');
    if(this.department_id==0){
      this.MenuService.fetchsetting(this.marbledata).subscribe(q =>{
          this.marblecountry=q['country'];
          this.marblecolors=q['colors'];
          this.marblecategory=q['category'];
        
          this.marblenames=q['marblenames'];
          this.mediaplatform=q['platform'];
          localStorage.setItem('aboutus',q['aboutus']);
          localStorage.setItem('legal',q['leagal']);
          this.loginname=localStorage.getItem('login_name');

        })
      }
     }
     logoutAction() {
      localStorage.setItem('login_name','');
      localStorage.setItem('login_email','');
      localStorage.setItem('login_userid','');
      localStorage.setItem('lastidvenquiryid','');
      localStorage.setItem('post_type','');
      localStorage.setItem('login_address','');
      localStorage.setItem('last_marbleid','');
      localStorage.setItem('post_created_date','');
      localStorage.setItem('lastfavoriteid','');
      localStorage.setItem('posttype','');
      localStorage.setItem('social_post','');
      localStorage.setItem('marblenames','');
      localStorage.setItem('last_appid','');
      localStorage.setItem('page_marble_type','');
      localStorage.setItem('app_application_id','');
      localStorage.setItem('login_userid','');
      localStorage.setItem('lastidvideoid','');
      localStorage.setItem('last_marblelist_id','');
      localStorage.setItem('page_marble_info','');
      localStorage.setItem('login_mobile','');
      localStorage.setItem('filter','');
      localStorage.setItem('enquiry_details','');
      localStorage.setItem('department_id','');
      localStorage.setItem('rol_id','');

      this.authService.logout();
    }
    loadmenuname()
  {
   
    this.loginname=localStorage.getItem('login_name');
  }
}
