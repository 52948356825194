import { HttpClientModule } from '@angular/common/http';
import { NgModule,ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { CallLog } from '@ionic-native/call-log/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';



import { CameraPreview } from '@ionic-native/camera-preview/ngx';


import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [

    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonBottomSheetModule
  ],
  providers: [
    AndroidPermissions,
    CallLog,
    CallNumber,
    StatusBar,
    SplashScreen,
    SpeechRecognition,
    TextToSpeech,
    HttpClientModule,
    BarcodeScanner,
    Camera,
    CameraPreview,
    Crop,
    File,
    PhotoViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
  
}
