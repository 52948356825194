import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private routerInfo: BehaviorSubject<boolean>;

 

  constructor(private httpService: HttpService) {
    this.routerInfo = new BehaviorSubject<boolean>(false);

   }

   

  getValue(): Observable<boolean> {
    return this.routerInfo.asObservable();
  }
  setValue(newValue): void {
    this.routerInfo.next(newValue);
  }
  
  fetchsetting(postData: any)
  {
    
      return this.httpService.post('settings', postData);
  }
  fetchcontact(postData: any)
  {
    
      return this.httpService.post('settings', postData);
  }
  
}
