import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
const API_URL=environment.apiUrl;
localStorage.setItem('last_marble_id','');
@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  postData = {
    user_id: '',
    token: '',
    last_marble_id: '',
    countryinput:'',
    applicationinput:'',
    colorinput:'',
    marblenameinput:'',
    
  };
  
  constructor(private httpService: HttpService) { }

  getMessages(postData: any){


    return this.httpService.post('fetchmarbles', postData);
    
  }
  getmarblenames(postData: any){
    return this.httpService.post('readmarblejson', postData);
  }
  getmarblenamesjsonapi(postData: any){
    return this.httpService.post('readmarblejson', postData);
  }
  getbarcodedata(postData: any){
    return this.httpService.post('getbarcodedata', postData);
  }
  
  getmarblenamessample(postData: any){
    return this.httpService.post('searchmarblenamessample', postData);
  }
}
