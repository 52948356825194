import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./index/index.module').then(m => m.IndexPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'modalpopup', loadChildren: './modalpopup/modalpopup.module#ModalpopupPageModule' },
  { path: 'marblelist', loadChildren: './pages/marblelist/marblelist.module#MarblelistPageModule' },
  {
    path: 'marblegallery',
    loadChildren: () => import('./pages/marblegallery/marblegallery.module').then( m => m.MarblegalleryPageModule)
  },
  {
    path: 'marbleenquiry',
    loadChildren: () => import('./pages/marbleenquiry/marbleenquiry.module').then( m => m.MarbleenquiryPageModule)
  },
  {
    path: 'callactivitydetails',
    loadChildren: () => import('./pages/callactivitydetails/callactivitydetails.module').then( m => m.CallactivitydetailsPageModule)
  },
  {
    path: 'mappinglead',
    loadChildren: () => import('./pages/mappinglead/mappinglead.module').then( m => m.MappingleadPageModule)
  },
  {
    path: 'addlead',
    loadChildren: () => import('./pages/addlead/addlead.module').then( m => m.AddleadPageModule)
  },
  {
    path: 'visualize',
    loadChildren: () => import('./pages/visualize/visualize.module').then( m => m.VisualizePageModule)
  },
  {
    path: 'calllog',
    loadChildren: () => import('./pages/calllog/calllog.module').then( m => m.CalllogPageModule)
  },
  {
    path: 'viewarchitect',
    loadChildren: () => import('./pages/viewarchitect/viewarchitect.module').then( m => m.ViewarchitectPageModule)
  },
  {
    path: 'viewmappinglead',
    loadChildren: () => import('./pages/viewmappinglead/viewmappinglead.module').then( m => m.ViewmappingleadPageModule)
  },
  {
    path: 'bussinessaccountinfo',
    loadChildren: () => import('./pages/bussinessaccountinfo/bussinessaccountinfo.module').then( m => m.BussinessaccountinfoPageModule)
  },
  {
    path: 'architect',
    loadChildren: () => import('./pages/architect/architect.module').then( m => m.ArchitectPageModule)
  },
  {
    path: 'addarchitect',
    loadChildren: () => import('./pages/addarchitect/addarchitect.module').then( m => m.AddarchitectPageModule)
  },
  {
    path: 'addmappinglead',
    loadChildren: () => import('./pages/addmappinglead/addmappinglead.module').then( m => m.AddmappingleadPageModule)
  },
  {
    path: 'editarchitect',
    loadChildren: () => import('./pages/editarchitect/editarchitect.module').then( m => m.EditarchitectPageModule)
  },
  {
    path: 'editmappinglead',
    loadChildren: () => import('./pages/editmappinglead/editmappinglead.module').then( m => m.EditmappingleadPageModule)
  },
  {
    path: 'bussinessaccount',
    loadChildren: () => import('./pages/bussinessaccount/bussinessaccount.module').then( m => m.BussinessaccountPageModule)
  },
  {
    path: 'addbussinessaccount',
    loadChildren: () => import('./pages/addbussinessaccount/addbussinessaccount.module').then( m => m.AddbussinessaccountPageModule)
  },
  {
    path: 'editbussinessaccount',
    loadChildren: () => import('./pages/editbussinessaccount/editbussinessaccount.module').then( m => m.EditbussinessaccountPageModule)
  },
  {
    path: 'editlead',
    loadChildren: () => import('./pages/editlead/editlead.module').then( m => m.EditleadPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then( m => m.LegalPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./pages/aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'leaddetails',
    loadChildren: () => import('./pages/leaddetails/leaddetails.module').then( m => m.LeaddetailsPageModule)
  },
  {
    path: 'quarrygaller',
    loadChildren: () => import('./pages/quarrygaller/quarrygaller.module').then( m => m.QuarrygallerPageModule)
  },
  {
    path: 'myprofile',
    loadChildren: () => import('./pages/myprofile/myprofile.module').then( m => m.MyprofilePageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'enquiry',
    loadChildren: () => import('./pages/enquiry/enquiry.module').then( m => m.EnquiryPageModule)
  },
  {
    path: 'myenquiry',
    loadChildren: () => import('./pages/myenquiry/myenquiry.module').then( m => m.MyenquiryPageModule)
  },
  {
    path: 'marblelistinfo',
    loadChildren: () => import('./pages/marblelistinfo/marblelistinfo.module').then( m => m.MarblelistinfoPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'enquirydetail',
    loadChildren: () => import('./pages/enquirydetail/enquirydetail.module').then( m => m.EnquirydetailPageModule)
  },
  {
    path: 'marblefaq',
    loadChildren: () => import('./pages/marblefaq/marblefaq.module').then( m => m.MarblefaqPageModule)
  },
  {
    path: 'appdashboard',
    loadChildren: () => import('./pages/appdashboard/appdashboard.module').then( m => m.AppdashboardPageModule)
  },
  {
    path: 'applicationlist',
    loadChildren: () => import('./pages/applicationlist/applicationlist.module').then( m => m.ApplicationlistPageModule)
  },
  {
    path: 'myfavorite',
    loadChildren: () => import('./pages/myfavorite/myfavorite.module').then( m => m.MyfavoritePageModule)
  },
  {
    path: 'enterotps',
    loadChildren: () => import('./pages/enterotps/enterotps.module').then( m => m.EnterotpsPageModule)
  },
  {
    path: 'prelogin',
    loadChildren: () => import('./pages/prelogin/prelogin.module').then( m => m.PreloginPageModule)
  },
  {
    path: 'myorders',
    loadChildren: () => import('./pages/myorders/myorders.module').then( m => m.MyordersPageModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./pages/invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'lead',
    loadChildren: () => import('./pages/lead/lead.module').then( m => m.LeadPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'deal',
    loadChildren: () => import('./pages/deal/deal.module').then( m => m.DealPageModule)
  },
  {
    path: 'invoicelist',
    loadChildren: () => import('./pages/invoicelist/invoicelist.module').then( m => m.InvoicelistPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./pages/request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'performa',
    loadChildren: () => import('./pages/performa/performa.module').then( m => m.PerformaPageModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('./pages/sales/sales.module').then( m => m.SalesPageModule)
  },
  {
    path: 'challanlist',
    loadChildren: () => import('./pages/challanlist/challanlist.module').then( m => m.ChallanlistPageModule)
  },
  {
    path: 'challan',
    loadChildren: () => import('./pages/challan/challan.module').then( m => m.ChallanPageModule)
  },
  {
    path: 'requestlist',
    loadChildren: () => import('./pages/requestlist/requestlist.module').then( m => m.RequestlistPageModule)
  },
  {
    path: 'performalist',
    loadChildren: () => import('./pages/performalist/performalist.module').then( m => m.PerformalistPageModule)
  },
  {
    path: 'orderdetail',
    loadChildren: () => import('./pages/orderdetail/orderdetail.module').then( m => m.OrderdetailPageModule)
  },
  {
    path: 'requestsample',
    loadChildren: () => import('./pages/requestsample/requestsample.module').then( m => m.RequestsamplePageModule)
  },
  {
    path: 'bookappointment',
    loadChildren: () => import('./pages/bookappointment/bookappointment.module').then( m => m.BookappointmentPageModule)
  },
  {
    path: 'tourlist',
    loadChildren: () => import('./pages/tourlist/tourlist.module').then( m => m.TourlistPageModule)
  },
  {
    path: 'addtour',
    loadChildren: () => import('./pages/addtour/addtour.module').then( m => m.AddtourPageModule)
  },
  {
    path: 'edittour',
    loadChildren: () => import('./pages/edittour/edittour.module').then( m => m.EdittourPageModule)
  },
  {
    path: 'viewtour',
    loadChildren: () => import('./pages/viewtour/viewtour.module').then( m => m.ViewtourPageModule)
  },
  {
    path: 'starttour',
    loadChildren: () => import('./pages/starttour/starttour.module').then( m => m.StarttourPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'slabinfo',
    loadChildren: () => import('./pages/slabinfo/slabinfo.module').then( m => m.SlabinfoPageModule)
  },
  {
    path: 'requestdetails',
    loadChildren: () => import('./pages/requestdetails/requestdetails.module').then( m => m.RequestdetailsPageModule)
  },
  {
    path: 'stockdetails',
    loadChildren: () => import('./pages/stockdetails/stockdetails.module').then( m => m.StockdetailsPageModule)
  },
  {
    path: 'searchsample',
    loadChildren: () => import('./pages/searchsample/searchsample.module').then( m => m.SearchsamplePageModule)
  },
  {
    path: 'stockdetails',
    loadChildren: () => import('./pages/stockdetails/stockdetails.module').then( m => m.StockdetailsPageModule)
  },
  {
    path: 'stockin',
    loadChildren: () => import('./pages/stockin/stockin.module').then( m => m.StockinPageModule)
  },
  {
    path: 'searchinventry',
    loadChildren: () => import('./pages/searchinventry/searchinventry.module').then( m => m.SearchinventryPageModule)
  },
  {
    path: 'searchsample',
    loadChildren: () => import('./pages/searchsample/searchsample.module').then( m => m.SearchsamplePageModule)
  }
  ,
  {
    path: 'otherrequestdetails',
    loadChildren: () => import('./pages/otherrequestdetails/otherrequestdetails.module').then( m => m.OtherrequestdetailsPageModule)
  },
  {
    path: 'addotherrequest',
    loadChildren: () => import('./pages/addotherrequest/addotherrequest.module').then( m => m.AddotherrequestPageModule)
  },
  {
    path: 'slabinfodetails',
    loadChildren: () => import('./pages/slabinfodetails/slabinfodetails.module').then( m => m.SlabinfodetailsPageModule)
  }
  ,
  {
    path: 'slabinfomesurment',
    loadChildren: () => import('./pages/slabinfomesurment/slabinfomesurment.module').then( m => m.SlabinfomesurmentPageModule)
  },
  {
    path: 'samplerequested',
    loadChildren: () => import('./pages/samplerequested/samplerequested.module').then( m => m.SamplerequestedPageModule)
  },
  {
    path: 'otherrequest',
    loadChildren: () => import('./pages/otherrequest/otherrequest.module').then( m => m.OtherrequestPageModule)
  },
  {
    path: 'sampleinfo',
    loadChildren: () => import('./pages/sampleinfo/sampleinfo.module').then( m => m.SampleinfoPageModule)
  }
  ,
  {
    path: 'addcheckin',
    loadChildren: () => import('./pages/addcheckin/addcheckin.module').then( m => m.AddcheckinPageModule)
  }
  ,
  {
    path: 'checkinlist',
    loadChildren: () => import('./pages/checkinlist/checkinlist.module').then( m => m.CheckinlistPageModule)
  }
  ,
  {
    path: 'checkindetails',
    loadChildren: () => import('./pages/checkindetails/checkindetails.module').then( m => m.CheckindetailsPageModule)
  }
  ,
  {
    path: 'thankyouwalkin',
    loadChildren: () => import('./pages/thankyouwalkin/thankyouwalkin.module').then( m => m.ThankyouwalkinPageModule)
  }
  ,
  {
    path: 'addblocking',
    loadChildren: () => import('./pages/addblocking/addblocking.module').then( m => m.AddblockingPageModule)
  }
  ,
  {
    path: 'blocking',
    loadChildren: () => import('./pages/blocking/blocking.module').then( m => m.BlockingPageModule)
  }
  
  
  
  
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
